<template>
  <div class="box">
    <div class="banner">
      <div class="item">
        <div class="t-wrap">
          <img
            :src="
              baseApi +
              '/public/financial/dyna/loadFile?CMD=HTML&forceDownLoad=false&uuid=' +
              obj.fpCrfileUuid
            "
            alt="暂无图片"
          />
          <div class="info">
            <div class="col">
              <div class="title">{{ obj.fpProductName }}</div>
              <div class="num">
                已成功对接<span>{{ obj.fpDockingNum }}</span
                >笔
              </div>
            </div>
            <div class="col">
              <div class="star">
                <div class="text">产品评价</div>
                <i v-for="(item, index) of 5" :key="index"></i>
              </div>
              <div class="star">
                <div class="text">银行满意度</div>
                <i v-for="(item, index) of 5" :key="index"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="b-wrap">
          <div class="detail">
            <div class="text">贷款额度</div>
            <div class="num">{{ obj.fpLoanAmount }}万元</div>
          </div>
          <div class="detail">
            <div class="text">担保方式</div>
            <div class="num">{{ obj.fpAssureMeans }}</div>
          </div>
          <div class="detail">
            <div class="text">贷款期限</div>
            <div class="num">{{ obj.fpLoanDeadline }}月</div>
          </div>
          <div class="detail">
            <div class="text">参考利率</div>
            {{ obj.fpReferenceRateStart }}%~{{ obj.fpReferenceRateEnd }}%
          </div>
        </div>
      </div>
    </div>
    <div class="part">
      <div class="title">
        <img src="@/assets/images/loanDetail/cpjs.png" alt="" class="img1" />
        <div class="text">产品介绍</div>
      </div>
      <div class="item">
        {{ obj.fpProductIntroduce }}
      </div>
    </div>
    <div class="part">
      <div class="title">
        <img src="@/assets/images/loanDetail/cptd.png" alt="" class="img2" />
        <div class="text">产品特点</div>
      </div>
      <div class="item">
        {{ obj.fpProductSpecial }}
      </div>
    </div>
    <div class="part">
      <div class="title">
        <img src="@/assets/images/loanDetail/sqtj.png" alt="" class="img3" />
        <div class="text">申请条件</div>
      </div>
      <div class="item">
        {{ obj.fpApplyCondition }}
      </div>
    </div>
    <div class="part">
      <div class="title">
        <img src="@/assets/images/loanDetail/sqcl.png" alt="" class="img4" />
        <div class="text">申请材料</div>
      </div>
      <div class="item">
        {{ obj.fpApplyMaterial }}
      </div>
    </div>
    <div class="btn" @click="apply">申请</div>
  </div>
</template>

<script>
import {
  financialBankProductDetail,
  applyFinancialProduct,
} from "@/api/loan.js";
export default {
  name: "LoanDetail",
  data() {
    return {
      obj: "",
      baseApi: "/prod-api",
    };
  },
  mounted() {
    this.getFinancialBankProductDetail();
  },
  methods: {
    //申请
    apply() {
      if (!this.$route.query.company) {
        this.$toast("请先前往列表选择企业");
        return;
      }
      applyFinancialProduct({
        fpUuid: this.$route.query.uuid,
        openId: localStorage.getItem("openid"),
        companyName: this.$route.query.company,
      }).then((res) => {
        if (res.code == 200) {
          this.$toast.success(res.msg);
        } else {
          this.$toast.fail(res.actionErrors[0]);
        }
      });
    },
    getFinancialBankProductDetail() {
      financialBankProductDetail({
        fpUuid: this.$route.query.uuid,
      }).then((res) => {
        if (res.success) {
          this.obj = res.data;
        } else {
          this.$toast.fail(res.actionErrors[0]);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "./index.scss";
</style>
